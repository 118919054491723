import React, { useEffect } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export const Carousel = (props) => {

   useEffect(() => {
      const playButton = document.querySelector('.alice-carousel__play-btn-item');
      if (playButton) {
         playButton.style.display = 'none';
      }
   }, []);

   return (
      <div id="carousel" className="text-center">
         <div className="container">
            <div className="col-md-10 col-md-offset-1 section-title">
               <h3>
                  Trusted By Students of:
               </h3>
            </div>
            <div className="">
               <AliceCarousel
                  autoPlay
                  autoPlayControls
                  autoPlayStrategy="all"
                  autoPlayInterval={1000}
                  animationDuration={1000}
                  animationType="fadeout"
                  infinite
                  autoHeight={true}
                  autoWidth={true}
                  touchTracking={true}
                  disableDotsControls
                  disableButtonsControls
                  items={6}
                  responsive={{
                     0: { items: 1 },
                     600: { items: 2 },
                     1024: { items: 6 },
                  }}
               >
                  <img src="img/carousel/01.png" alt="Image 1"
                     style={{ width: "auto", height: "150px" }}
                  />
                  <img src="img/carousel/02.png" alt="Image 2"
                     style={{ width: "auto", height: "150px" }}
                  />
                  <img src="img/carousel/03.png" alt="Image 3"
                     style={{ width: "auto", height: "150px" }}
                  />
                  <img src="img/carousel/04.png" alt="Image 4"
                     style={{ width: "auto", height: "150px" }}
                  />
                  <img src="img/carousel/05.png" alt="Image 5"
                     style={{ width: "auto", height: "150px" }}
                  />
                  <img src="img/carousel/06.png" alt="Image 6"
                     style={{ width: "auto", height: "150px" }}
                  />
               </AliceCarousel>
            </div>
         </div>
      </div>
   );
};

import React from "react";
import aboutVideo from "../components/video/aboutVideo.mp4";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <h2>About Us</h2>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <video className="img-responsive" controls>
              <source src={aboutVideo} type="video/mp4" />
            </video>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                        <li key={`${d}-${i}`}>{d}</li>
                      ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                        <li key={`${d}-${i}`}> {d}</li>
                      ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <footer className="footer bg-dark text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <h5>QUICK LINKS</h5>
                <ul className="list-unstyled">
                  <li><a href="#about" className="text-white">About</a></li>
                  <li><a href="#services" className="text-white">Services</a></li>
                  <li><a href="#features" className="text-white">Features</a></li>
                  <li><a href="#testimonials" className="text-white">Testimonials</a></li>
                  <li><a href="#team" className="text-white"> Team</a></li>
                </ul>
              </div>
              <div className="col-md-4">
                <h5>SERVICES</h5>
                <ul className="list-unstyled">
                  <li><a href="#" className="text-white">Assignment Help Online</a></li>
                  <li><a href="#" className="text-white">Essay writing service</a></li>
                  <li><a href="#" className="text-white">Dissertation Help</a></li>
                  <li><a href="#" className="text-white">Technical Writing</a></li>
                  <li><a href="#" className="text-white">IT Solution</a></li>
                  <li><a href="#" className="text-white">Research Writing</a></li>
                </ul>
              </div>
              <div className="col-md-4">
                <h5>Contact Info</h5>
                <ul className="list-unstyled">
                  <li>
                    <p>
                      <span>
                        <i className="fa fa-map-marker"></i>{" "}Address:{" "}
                      </span>
                      {props.data ? props.data.address : "loading"}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <i className="fa fa-phone"></i>{" "}Phone:{" "}
                      </span>{" "}
                      {props.data ? props.data.phone : "loading"}
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        <i className="fa fa-envelope-o"></i>{" "}Email:{" "}
                      </span>{" "}
                      {props.data ? props.data.email : "loading"}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.instagram : "/"}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href={props.data ? props.data.linkedin : "/"}>
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            Copyright© {new Date().getFullYear() + ' '}  Academic Inquisition
            {/* <Link href="/"
              className="text-primary transition hover:text-secondary">
              Academic Inquisition
            </Link> */}
          </p>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
          <h3>Get Assignment Help UK for All Formats</h3>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="service-listing">
              <ul>
                <li>Term Paper</li>
                <li>Coursework</li>
                <li>Dissertation Writing</li>
                <li>Research Papers</li>
                <li>Thesis</li>
                <li>Essay</li>
                <li>Reports</li>
                <li>Case Studies</li>
                <li>CDR</li>
              </ul>
            </div>
            <div>
              <div className="rowi">
                <div className="col-12 col-md-4 col-lg-4">
                  <div className="mx-3 text-center">
                    <p className="text-danger display-4 font-weight-bold counter">10,000</p>
                    <p className="font-weight-bold">DELIVERED ORDERS</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <div className="mx-3 text-center">
                    <p className="text-danger display-4 font-weight-bold"><span className="counter">27</span>+</p>
                    <p className="font-weight-bold">EXPERTS</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <div className="mx-3 text-center">
                    <p className="text-danger display-4 font-weight-bold"><span className="counter">5</span>/9</p>
                    <p className="font-weight-bold">CLIENT RATINGS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12">
            <div className="right-listing">
              <div className="listing-card">
                <div className="top-head">Pricing and Free Features</div>
                <div className="listing-wrap">
                  <div className="left-one">
                    <img src="https://cdn1.myassignmenthelp.com/seo-revamp-assets/outline-circle.svg" alt="check" width="24" height="24" />
                    Plagiarism Report
                  </div>
                  <div className="right-free">
                    <span>FREE</span>
                  </div>
                </div>
                <div className="listing-wrap">
                  <div className="left-one">
                    <img src="https://cdn1.myassignmenthelp.com/seo-revamp-assets/outline-circle.svg" alt="check" width="24" height="24" />
                    A Top-Notch Academic Writer
                  </div>
                  <div className="right-free">
                    <span>FREE</span>
                  </div>
                </div>
                <div className="listing-wrap">
                  <div className="left-one">
                    <img src="https://cdn1.myassignmenthelp.com/seo-revamp-assets/outline-circle.svg" alt="check" width="24" height="24" />
                    Formatting
                  </div>
                  <div className="right-free">
                    <span>FREE</span>
                  </div>
                </div>
                <div className="listing-wrap">
                  <div className="left-one">
                    <img src="https://cdn1.myassignmenthelp.com/seo-revamp-assets/outline-circle.svg" alt="check" width="24" height="24" />
                    Outline
                  </div>
                  <div className="right-free">
                    <span>FREE</span>
                  </div>
                </div>
                <div className="listing-wrap">
                  <div className="left-one">
                    <img src="https://cdn1.myassignmenthelp.com/seo-revamp-assets/outline-circle.svg" alt="check" width="24" height="24" />
                    A Reference Page
                  </div>
                  <div className="right-free">
                    <span>FREE</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
